import React, { useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Line,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import dateFmt from "date-fns/format";
import classnames from "classnames";

import "./DisinfectionTimePlot.scss";

import { Color } from "../../constants";

export interface DFTPlotDataItem {
  datetime: Date;
  disinfectTimeHrs: number;
  occupiedTimeHrs: number;
}
export interface DisinfectionTimePlotProps {
  className?: string | string[];
  data: DFTPlotDataItem[];
  height: number;
  ymax: number;
}
export default function DisinfectionTimePlot(
  props: DisinfectionTimePlotProps,
): React.ReactElement {
  const { data, height, className, ymax } = props;

  const dataFmt = useMemo(
    () =>
      data.map((d) => ({
        datetime: dateFmt(d.datetime, "d/MM"),
        disinfectTimeHrs: d.disinfectTimeHrs.toFixed(2),
        occupiedTimeHrs: d.occupiedTimeHrs.toFixed(2),
      })),
    [data],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CLine = Line as any;

  return (
    <Paper
      classes={{ root: classnames("disinfection-time-plot", className) }}
      elevation={10}
    >
      <h5 className="plot-title">Hours of Disinfection Time</h5>
      <p className="plot-subtitle">Per Day</p>
      <ResponsiveContainer height={height} width="100%">
        <ComposedChart data={dataFmt}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="datetime"
            tick={{ fill: "black" }}
            style={{
              fontWeight: 500,
            }}
            label={{
              value: "day/month",
              position: "insideBottom",
            }}
            height={45}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            axisLine={false}
            tick={{ fill: "black" }}
            style={{
              fontWeight: 500,
            }}
            domain={[0, ymax]}
            scale="linear"
          />
          <Tooltip />
          <Bar
            yAxisId="left"
            dataKey="disinfectTimeHrs"
            name="Disinfection time"
            fill={Color.DarkPurple}
            label={{ fill: "black", position: "top" }}
            radius={[4, 4, 0, 0]}
          />
          <CLine
            label={{ fill: Color.LightPurple, position: "top" }}
            yAxisId="left"
            type="monotone"
            name="Occupied time"
            dataKey="occupiedTimeHrs"
            stroke={Color.Teal}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="line-legend">
        <div className="line-line" />
        <span className="line-legend-label">Hours occupied</span>
      </div>
    </Paper>
  );
}
