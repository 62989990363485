import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import dateParseISO from "date-fns/parseISO";
import axios from "./axios";
import { DeviceConnEvt } from "../types";
import { QueryOp } from "../constants";

const DEVICE_CONNECTED = "/device_conn";
const DEVICE_CONNECTED_LATEST = `${DEVICE_CONNECTED}/latest`;

export async function getDevicesConnected(
  deviceIds: string[],
  cancelTokenSource?: CancelTokenSource,
): Promise<DeviceConnEvt[]> {
  try {
    const resp: AxiosResponse = await axios.post(DEVICE_CONNECTED_LATEST, {
      device_id: {
        value: deviceIds,
        op: QueryOp.Eq,
      },
      cancelToken: cancelTokenSource?.token,
    });

    const { data } = resp;

    if (!Array.isArray(data)) {
      throw new Error("Malformed server response");
    }

    const evts: DeviceConnEvt[] = data.map((e) => ({
      ...e,
      evt_ts: dateParseISO(e.evt_ts),
    }));

    return evts;
  } catch (error) {
    if (Axios.isCancel(error)) {
      throw error;
    }

    throw error;
  }
}
