export enum PageUrl {
  Account = "/account",
  Dashboard = "/",
  DeviceOverview = "/device-overview",
  Settings = "/settings",
  SignIn = "/sign-in",
  Floorplan = "/floorplan",
  Reports = "/reports",
}

export enum UvLampOpMode {
  HumanNotDetected = "hu_not_det",
  Continuous = "cont",
}

export enum Color {
  DarkPurple = "#281b54",
  DarkerPurple = "#201254",
  LightPurple = "#e5daff",
  Purple = "#372969",
  BrightPurple = "#8447ff",
  Teal = "#58bd98",
  Black = "#333333",
  Red = "#FF5151",
}

export enum QueryOp {
  Eq = "eq",
  FuzzyEq = "fuzzy_eq",
  Gt = "gt",
  Ln = "ln",
  Overlap = "overlap",
}
