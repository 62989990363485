import { MdGroupOff, MdAllInclusive } from "react-icons/md";
import { UvLampOpMode, Color } from "../constants";

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
}

export function opModeToTitle(opMode: UvLampOpMode): string {
  switch (opMode) {
    case UvLampOpMode.HumanNotDetected:
      return "Without Human Presence";
    case UvLampOpMode.Continuous:
      return "With Human Presence";
    default:
      return "";
  }
}

export function opModeToIcon(opMode: UvLampOpMode): React.FC<IconProps> | null {
  switch (opMode) {
    case UvLampOpMode.HumanNotDetected:
      return MdGroupOff;
    case UvLampOpMode.Continuous:
      return MdAllInclusive;
    default:
      return null;
  }
}

export function opModeToDescription(opMode: UvLampOpMode): string[] {
  switch (opMode) {
    case UvLampOpMode.HumanNotDetected:
      return [
        "Continuously sanitizing, but turning off when humans are present.",
      ];
    case UvLampOpMode.Continuous:
      return [
        "Continuously sanitizing, including sanitizing when humans are present.",
      ];
    default:
      return [];
  }
}

export function metadataToStatus(connected: boolean): string[] {
  if (!connected) {
    return ["Offline", "Zener is offline.", "Configure to WiFi."];
  }

  return ["Online", "Zener is online."];
}

export function metadataToStatusTitle(connected: boolean): string {
  if (!connected) {
    return "Offline";
  }

  return "Online";
}

export function metadataToStatusDescription(connected: boolean): string[] {
  if (!connected) {
    return ["Zener is offline.", "Contact UVX for", "more details."];
  }

  return ["Zener is online."];
}

export function opModeToColor(opMode: UvLampOpMode): string {
  let color = Color.Black;

  switch (opMode) {
    case UvLampOpMode.HumanNotDetected:
      color = Color.Black;
      break;
    case UvLampOpMode.Continuous:
      color = Color.Teal;
      break;
    default:
      break;
  }

  return color;
}

export function metadataToStatusColor(connected: boolean): string {
  return connected ? Color.Teal : Color.Black;
}
