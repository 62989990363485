import React, { useState } from "react";
import { Portal } from "react-portal";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import "./ChangePasswordModal.scss";

interface ChangePasswordModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ChangePasswordModal(
  props: ChangePasswordModalProps,
): React.ReactElement {
  const { open, setOpen } = props;
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [oldPassword, setOldPassword] = useState<string>("");
  const { changePassword } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  async function handleSubmit(evt?: React.FormEvent<HTMLFormElement>) {
    if (evt !== undefined) {
      evt.preventDefault();
    }

    if (oldPassword === "") {
      enqueueSnackbar("Current Password cannot be empty", { variant: "error" });
      return;
    }

    if (password === "") {
      enqueueSnackbar("New Password cannot be empty", { variant: "error" });
      return;
    }

    if (password !== cPassword) {
      enqueueSnackbar("New Password does not match", { variant: "error" });
      return;
    }

    try {
      await changePassword(oldPassword, password);
      enqueueSnackbar("Successfully changed password", { variant: "success" });
      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  return (
    <Portal>
      {open && (
        <div className="change-password-modal-container">
          <div
            className="change-password-overlay"
            aria-hidden="true"
            role="button"
            onClick={() => {
              setOpen(false);
            }}
          />
          <div className="change-password-modal">
            <div className="change-password-titlespace">
              <div className="titlespace-span-container">
                <span className="titlespace-span">CHANGE PASSWORD</span>
              </div>
              <button
                className="close-modal"
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                X
              </button>
            </div>
            <div className="input-container">
              <span className="change-password-heading">
                Current Password:{" "}
              </span>
              <input
                className="input-field"
                type="password"
                value={oldPassword}
                onChange={(evt) => setOldPassword(evt.target.value)}
              />
            </div>
            <div className="input-container">
              <span className="change-password-heading">New Password: </span>
              <input
                className="input-field"
                type="password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </div>
            <div className="input-container">
              <span className="change-password-heading">
                Confirm new password:{" "}
              </span>
              <input
                className="input-field"
                type="password"
                value={cPassword}
                onChange={(evt) => setCPassword(evt.target.value)}
              />
            </div>
            <div className="change-password-action">
              <button
                className="submit-email"
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                CANCEL
              </button>
              <button
                className="submit-email"
                type="button"
                onClick={() => handleSubmit()}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
}
