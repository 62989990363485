import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PageUrl } from "../constants";

const PAGE_TITLE_MAP: Record<PageUrl, string> = {
  [PageUrl.Account]: "UVX | Account",
  [PageUrl.Dashboard]: "UVX | Home",
  [PageUrl.DeviceOverview]: "UVX | Zener Overview",
  [PageUrl.Settings]: "UVX | Settings",
  [PageUrl.SignIn]: "UVX | Sign In",
  [PageUrl.Floorplan]: "UVX | Floorplan",
  [PageUrl.Reports]: "UVX | Reports",
};

export default function useDocTitle(): void {
  const location = useLocation();

  useEffect(() => {
    const path: PageUrl = location.pathname as PageUrl;

    let nextTitle = PAGE_TITLE_MAP[path];
    if (nextTitle === undefined) {
      nextTitle = "UVX";
    }

    document.title = nextTitle;
  }, [location.pathname]);
}
