import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import parseISO from "date-fns/parseISO";
import axios from "./axios";
import { DeviceMetadata } from "../types";
import * as ZenerNetUtils from "./zener";
import Device from "../lib/Device";
import { DeviceConnNetUtils } from ".";

const DEVICES_URL = "/devices";
const GET_DEVICES_URL = `${DEVICES_URL}/query`;

export async function getDevices(): Promise<DeviceMetadata[] | null> {
  const resp: AxiosResponse = await axios.post(GET_DEVICES_URL);

  if (!Array.isArray(resp.data)) {
    // eslint-disable-next-line no-console
    console.warn(`${DEVICES_URL} payload not array: `, resp.data);
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return resp.data.map((d: any) => ({
    deviceId: d.device_id,
    name: d.name,
    description: d.description,
    version: d.version,
    ownerId: d.owner_id,
    connected: d.connected,
    createdAt: parseISO(d.created_at),
    updatedAt: parseISO(d.updated_at),
    height: d.height,
  }));
}

export async function postDevice(
  deviceId: string,
  name?: string,
  description?: string,
  height?: number,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      name,
      description,
      height,
    };

    return await axios.post(DEVICES_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function putDevice(
  deviceId: string,
  name: string,
  description: string,
  version: string,
  height: number,
  cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse | null> {
  try {
    const payload = {
      device_id: deviceId,
      name,
      description,
      version,
      height,
    };

    return await axios.put(DEVICES_URL, payload, {
      cancelToken: cancelTokenSource?.token,
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return null;
    }

    throw error;
  }
}

export async function getDevicesAll(): Promise<Device[] | null> {
  const metadatasResp: AxiosResponse = await axios.post(GET_DEVICES_URL);

  if (!Array.isArray(metadatasResp.data)) {
    // eslint-disable-next-line no-console
    console.warn(`${DEVICES_URL} payload not array: `, metadatasResp.data);
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const metadatas: DeviceMetadata[] = metadatasResp.data.map((d: any) => ({
    deviceId: d.device_id,
    name: d.name,
    description: d.description,
    version: d.version,
    ownerId: d.owner_id,
    connected: d.connected,
    createdAt: parseISO(d.created_at),
    updatedAt: parseISO(d.updated_at),
    height: d.height,
  }));

  const deviceIds = metadatas.map((m) => m.deviceId);

  const ctrlsPromise = ZenerNetUtils.getControls(deviceIds);
  const statusesPromise = ZenerNetUtils.getStatuses(deviceIds);
  const connEvtsPromise = DeviceConnNetUtils.getDevicesConnected(deviceIds);

  const [ctrls, statuses, connEvts] = await Promise.all([
    ctrlsPromise,
    statusesPromise,
    connEvtsPromise,
  ]);

  const devices: Device[] = [];
  metadatas.forEach((m: DeviceMetadata) => {
    const ctrl = ctrls.find((c) => c?.deviceId === m.deviceId);
    const status = statuses.find((s) => s?.deviceId === m.deviceId);
    const connEvt = connEvts.find((s) => s?.device_id === m.deviceId) || null;

    if (
      ctrl === undefined ||
      ctrl === null ||
      status === undefined ||
      status === null
    ) {
      console.warn(`Device "${m.deviceId}" has a null control or status`);
      return;
    }

    const device = new Device(m, ctrl, status, connEvt);
    devices.push(device);
  });

  return devices;
}
