import React, { useState, useEffect, useRef, useCallback } from "react";
import Axios, { CancelTokenSource } from "axios";
import fmtDate from "date-fns/format";
import { CgSpinner } from "react-icons/cg";

import { ZenerNetUtils } from "../../networking";
import Button from "../Button";
import Device from "../../lib/Device";
import {
  metadataToStatus,
  metadataToStatusColor,
  opModeToTitle,
} from "../../utils/stringifiers";

import "./ZenerDetailsImmutable.scss";

function fmtUvLampFiringTime(firingTimeMs: number): string {
  const firingTimeHours = firingTimeMs / (1000 * 60 * 60);

  if (firingTimeHours < 1) {
    return "less than 1 hour";
  }

  if (firingTimeHours < 2) {
    return "1 hour";
  }

  return `${Math.floor(firingTimeHours).toFixed(0)} hours`;
}

export interface ZenerDetailsImmutableProps {
  zener: Device;
  onClose: () => void;
  onStartEditing: () => void;
}
export default function ZenerDetailsImmutable(
  props: ZenerDetailsImmutableProps,
): React.ReactElement {
  const { zener, onClose, onStartEditing } = props;
  const [tft, setTft] = useState<number | null>(null);
  const [tftError, setTftError] = useState<string | null>(null);
  const tftCTSRef = useRef<CancelTokenSource | null>(null);

  const OpModeIcon = zener.opModeIcon();

  const fetchMetrics = useCallback(async () => {
    if (tftCTSRef.current !== null) {
      tftCTSRef.current.cancel();
    }
    tftCTSRef.current = Axios.CancelToken.source();

    try {
      const tftResp = await ZenerNetUtils.getTotalFiringTimeMs(
        zener.deviceId(),
        tftCTSRef.current,
      );
      if (tftResp == null) {
        setTftError("Unable to load firing time");
        setTft(null);
        return;
      }

      setTft(tftResp.lampFiringTimeMs);
      setTftError(null);
    } catch (error) {
      if (Axios.isCancel(error)) {
        return;
      }
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [zener.deviceId]);

  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  return (
    <div className="zener-details-immutable">
      <div className="header-row">
        <h3 className="name">{zener.metadata.name}</h3>
        <div className="status">
          <div
            className="status-circle"
            style={{
              backgroundColor: metadataToStatusColor(zener.isConnected()),
            }}
          />
          <span
            className="status-text"
            style={{ color: metadataToStatusColor(zener.isConnected()) }}
          >
            {metadataToStatus(zener.isConnected())[0]}
          </span>
        </div>
      </div>
      <p className="description">{zener.metadata.description}</p>
      <div className="prop-row">
        <span>Operating Mode</span>
        <div className="prop-value">
          {OpModeIcon !== null && (
            <OpModeIcon
              className="op-mode-icon"
              style={{ color: zener.opModeColor() }}
            />
          )}
          <span style={{ color: zener.opModeColor() }}>
            {opModeToTitle(zener.control.uvLampOpMode)}
          </span>
        </div>
      </div>
      <hr />
      <div className="prop-row">
        <span>Lamp Hours</span>
        <div className="prop-value">
          {tft === null && tftError == null && (
            <CgSpinner className="loading-spinner" />
          )}
          {tft !== null && <span>{fmtUvLampFiringTime(tft)}</span>}
          {tftError !== null && <span className="prop-error">{tftError}</span>}
        </div>
      </div>
      <hr />
      <div className="prop-row">
        <span>Installation Height</span>
        <div className="prop-value">
          <span>{zener.metadata.height.toFixed(1)}&nbsp;meters</span>
        </div>
      </div>
      <hr />
      <div className="prop-row">
        <span>Last Modified</span>
        <div className="prop-value">
          <span>{fmtDate(zener.metadata.updatedAt, "MMM d, yyyy")}</span>
        </div>
      </div>
      <hr />
      <div className="prop-row">
        <span>Date Created</span>
        <div className="prop-value">
          <span>{fmtDate(zener.metadata.createdAt, "MMM d, yyyy")}</span>
        </div>
      </div>
      <div className="action-row">
        <Button color="secondary" onClick={onClose}>
          Back
        </Button>
        <Button color="primary" onClick={onStartEditing}>
          Edit
        </Button>
      </div>
    </div>
  );
}
