import {
  ZenerStatus,
  ZenerControl,
  DeviceMetadata,
  DeviceData,
  DeviceConnEvt,
} from "../types";
import { Color, UvLampOpMode } from "../constants";
import { opModeToTitle, IconProps, opModeToIcon } from "../utils/stringifiers";

export default class Device {
  public metadata: DeviceMetadata;

  public control: ZenerControl;

  public status: ZenerStatus;

  public connEvt: DeviceConnEvt | null;

  constructor(
    metadata: DeviceMetadata,
    control: ZenerControl,
    status: ZenerStatus,
    connEvt: DeviceConnEvt | null,
  ) {
    this.metadata = metadata;
    this.control = control;
    this.status = status;
    this.connEvt = connEvt;
  }

  public deviceId(): string {
    return this.metadata.deviceId;
  }

  public isConnected(): boolean {
    return Boolean(this.connEvt?.connected);
  }

  public toFlatObj(): DeviceData {
    return {
      ...this.metadata,
      ...this.control,
      ...this.status,
      connected: this.isConnected(),
    };
  }

  public opModeTitle(): string | null {
    return opModeToTitle(this.control.uvLampOpMode);
  }

  public opModeDescription(): string[] | null {
    switch (this.control.uvLampOpMode) {
      case UvLampOpMode.HumanNotDetected:
        return [
          "Continuously sanitizing, but turning off when humans are present.",
        ];
      case UvLampOpMode.Continuous:
        return [
          "Continuously sanitizing, including sanitizing when humans are present.",
        ];
      default:
        return null;
    }
  }

  public opModeIcon(): React.FC<IconProps> | null {
    return opModeToIcon(this.control.uvLampOpMode);
  }

  public opModeColor(): Color {
    let color = Color.Black;

    switch (this.control.uvLampOpMode) {
      case UvLampOpMode.HumanNotDetected:
        color = Color.Black;
        break;
      case UvLampOpMode.Continuous:
        color = Color.Teal;
        break;
      default:
        break;
    }

    return color;
  }

  public statusTitle(): string {
    if (!this.isConnected()) {
      return "Offline";
    }

    return "Online";
  }

  public statusDescription(): string[] {
    if (!this.isConnected()) {
      return ["Zener is offline.", "Configure to WiFi."];
    }

    return ["Zener is online."];
  }

  public statusColor(): Color {
    return this.isConnected() ? Color.Teal : Color.Black;
  }

  public clone(): Device {
    return new Device(this.metadata, this.control, this.status, this.connEvt);
  }
}
