import React from "react";

import "./Footer.scss";

export default function Footer(): React.ReactElement {
  return (
    <div className="footer">
      <span className="help">
        For further assistance, please contact UVX at &nbsp;
        <a href="mailto:info@uvxinc.com">info@uvxinc.com</a>
      </span>
    </div>
  );
}
