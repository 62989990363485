import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";

import ResetPasswordModal from "../components/ResetPasswordModal/ResetPasswordModal";

export interface AuthUserAttributes {
  email: string;
  email_verified: boolean;
  sub: string;
}
export interface AuthToken {
  jwtToken: string;
  payload?: Record<string, string | number>;
}
export interface AuthUserSession {
  accessToken: AuthToken;
  refreshToken: AuthToken;
  clockDrift: number;
}
export interface AuthUser {
  attributes: AuthUserAttributes;
  signInUserSesson: AuthUserSession;
  authenticationFlowType: string;
  username: string;
  challengeName: string;
}

export enum AuthUserState {
  Authed,
  NotAuthed,
  Updating,
}

export interface AuthContext {
  authUser: AuthUser | null;
  authUserState: AuthUserState;
  setAuthUser: React.Dispatch<React.SetStateAction<AuthUser | null>>;
}

export const AuthContextObj = React.createContext<AuthContext>({
  authUser: null,
  authUserState: AuthUserState.Updating,
  setAuthUser: () => {},
});

export default function withAuthN<Props>(
  AuthedComponent: React.FC<Props>,
): React.FunctionComponent<Props> {
  return function WithAuthN(props: Props) {
    const [authUser, setAuthUser] = useState<AuthUser | null>(null);
    const [authUserState, setAuthUserState] = useState<AuthUserState>(
      AuthUserState.Updating,
    );
    const [resetPass, setResetPass] = useState(false);

    async function getCurrentUser() {
      try {
        const nextAuthUser = await Auth.currentAuthenticatedUser();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setAuthUser(nextAuthUser as any as AuthUser);
        setAuthUserState(AuthUserState.Authed);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setAuthUserState(AuthUserState.NotAuthed);
      }
    }

    useEffect(() => {
      getCurrentUser();
    }, []);

    useEffect(() => {
      if (!authUser) {
        Sentry.configureScope((scope) => scope.setUser(null));
      } else {
        Sentry.setUser({
          id: authUser.username,
          email: authUser.attributes?.email,
        });
      }
    }, [authUser]);

    useEffect(() => {
      if (
        authUser !== null &&
        authUser.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        setResetPass(true);
      }
    }, [authUser]);

    const contextState: AuthContext = { authUser, authUserState, setAuthUser };

    return (
      <AuthContextObj.Provider value={contextState}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <AuthedComponent {...(props as any)} />
        <ResetPasswordModal open={resetPass} setOpen={setResetPass} />
      </AuthContextObj.Provider>
    );
  };
}
