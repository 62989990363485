import React, { useState } from "react";
import { useSnackbar } from "notistack";
import SearchBar from "../../components/SearchBar";
import ZenerTable from "../../components/ZenerTable";
import ZenerDetailsModal from "../../components/ZenerDetailsModal";
import Loading from "../../components/Loading";
import Device from "../../lib/Device";
import { ZenerNetUtils } from "../../networking";

import "./Dashboard.scss";

export interface DashboardProps {
  devices: Device[];
  reload: () => void;
}

export default function DashboardPage(
  props: DashboardProps,
): React.ReactElement {
  const { devices, reload } = props;
  const [searchBarFilter, SetSearchBarFilter] = useState<string | null>(null);
  const [focusedDeviceId, setFocusedDeviceId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  let focusedDevice: Device | null = null;
  if (focusedDeviceId !== null) {
    const nextFocusedDevice = devices.find(
      (d) => d.deviceId() === focusedDeviceId,
    );
    if (nextFocusedDevice !== undefined) {
      focusedDevice = nextFocusedDevice;
    }
  }

  async function handleSetEnabled(deviceId: string) {
    const prev = devices.find((d) => d.deviceId() === deviceId);
    if (prev === undefined) {
      // eslint-disable-next-line no-console
      console.error(new Error("Device undefined"));
      return;
    }

    setLoading(true);
    const nextChecked = !prev.control.uvLampEnabled;
    try {
      await ZenerNetUtils.postControl(deviceId, nextChecked, undefined);
      enqueueSnackbar("Updated device", { variant: "success" });
      reload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(new Error("Device control undefined"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="dashboard">
      <div className="dashboard-middle">
        <SearchBar
          className="dash-search"
          label="SEARCH ZENER"
          onChange={SetSearchBarFilter}
          value={searchBarFilter}
        />
        <span className="middle-notice">
          Click on the Zener below to view its details
        </span>

        <div className="middle-zeners">
          <ZenerTable
            devices={devices}
            query={searchBarFilter || ""}
            onSelect={setFocusedDeviceId}
            onSetEnabled={handleSetEnabled}
          />
        </div>
      </div>
      {focusedDeviceId !== null && focusedDevice !== null && (
        <ZenerDetailsModal
          zener={focusedDevice}
          onClose={() => setFocusedDeviceId(null)}
          onReloadDevices={reload}
        />
      )}
      {loading && <Loading overlay message="Updating device..." />}
    </div>
  );
}
