import React, { useRef } from "react";
import classnames from "classnames";
import "./Button.css";

export interface ButtonProps {
  className?: string;
  color?: "primary" | "secondary";
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
}
export default function Button(props: ButtonProps): React.ReactElement {
  const { className, children, color, onClick, disabled } = props;
  const animRef = useRef<null | number>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // eslint-disable-next-line react/destructuring-assignment
  const fullWidth = Boolean(props.fullWidth);

  let colorClass: undefined | string;
  if (color === "primary") {
    colorClass = "uvx-button-primary-color";
  } else if (color === "secondary") {
    colorClass = "uvx-button-secondary-color";
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (buttonRef.current === null) {
      return;
    }
    if (animRef.current !== null) {
      window.clearTimeout(animRef.current);
      buttonRef.current.classList.remove("uvx-button-press-animation");
    }

    buttonRef.current.classList.add("uvx-button-press-animation");
    animRef.current = window.setTimeout(() => {
      if (buttonRef.current === null) {
        return;
      }
      buttonRef.current.classList.remove("uvx-button-press-animation");
    }, 300);

    if (onClick !== undefined) {
      onClick(event);
    }
  }

  return (
    <button
      type="button"
      ref={buttonRef}
      className={classnames(
        "uvx-button",
        colorClass,
        fullWidth && "uvx-button-full-width",
        className,
      )}
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
