import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import useAuth, { UseAuthInterface } from "../../hooks/useAuth";
import { PageUrl } from "../../constants";
import ForgotPasswordModal from "../../components/ForgotPasswordModal";
import useDocTitle from "../../hooks/useDocTitle";

import "./SignInPage.scss";

export default function SignInPage(): React.ReactElement {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn, authUser }: UseAuthInterface = useAuth();
  const [forgotPass, setForgotPass] = useState<boolean>(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useDocTitle();

  async function handleSignIn(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();

    try {
      await signIn(email, password);
      enqueueSnackbar("Successfully signed in", { variant: "success" });
      history.push(PageUrl.Dashboard);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  function handleInputChangeClosure(
    setter: React.Dispatch<React.SetStateAction<string>>,
  ): (evt: React.ChangeEvent<HTMLTextAreaElement>) => void {
    return function handleInputChange(
      evt: React.ChangeEvent<HTMLTextAreaElement>,
    ) {
      const nextValue = evt.currentTarget.value;
      setter(nextValue);
    };
  }
  if (authUser !== null) {
    history.push(PageUrl.Dashboard);
  }
  return (
    <div className="sign-in-page">
      <img
        alt="wow"
        className="sign-in-logo"
        src={`${process.env.PUBLIC_URL}/UVX-02.svg`}
      />
      <Paper classes={{ root: "sign-in-form" }}>
        <span className="sign-in-title">SIGN IN</span>
        <form onSubmit={handleSignIn}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={handleInputChangeClosure(setEmail)}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            onChange={handleInputChangeClosure(setPassword)}
          />
          <div className="sign-in-action-row">
            <Button
              fullWidth
              classes={{ root: "sign-in-submit-btn" }}
              type="submit"
              variant="contained"
            >
              Sign In
            </Button>
          </div>
        </form>
      </Paper>
      <Paper classes={{ root: "create-account-footer" }}>
        <div>
          <span>New to UVX?</span>
          &nbsp;
          <a
            href="https://uvxinc.com/contact"
            style={{ textDecoration: "none" }}
          >
            Create an account
          </a>
        </div>
        <Button
          fullWidth
          classes={{ root: "forgot-pass-submit-btn" }}
          type="submit"
          variant="contained"
          onClick={() => {
            setForgotPass(true);
          }}
        >
          Forgot Password
        </Button>
      </Paper>
      <ForgotPasswordModal open={forgotPass} setOpen={setForgotPass} />
    </div>
  );
}
