import React, { useState } from "react";
import { MdHelp } from "react-icons/md";
import Popover from "@material-ui/core/Popover";
import classnames from "classnames";

import "./ColumnInfoPopover.scss";

export interface ColumnInfoPopoverProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any | null;
  className?: string;
}
export default function ColumnInfoPopover(
  props: ColumnInfoPopoverProps,
): React.ReactElement | null {
  const { className, children } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [id] = useState(`popover-id-${Math.random()}`);

  if (children === null) {
    return null;
  }

  const open = Boolean(anchorEl);

  function handleMouseEnter(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }
  function handleMouseLeave() {
    setAnchorEl(null);
  }

  return (
    <div className={classnames("column-info-popover", className)}>
      <div
        className="icon-popover"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
      >
        <MdHelp />
      </div>
      <Popover
        id={id}
        className="column-info-popover-content"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleMouseLeave}
        disableRestoreFocus
      >
        {children}
      </Popover>
    </div>
  );
}
