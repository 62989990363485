import { useContext } from "react";
import { Auth } from "aws-amplify";
import {
  AuthUser,
  AuthContextObj,
  AuthContext,
  AuthUserState,
} from "../hocs/withAuthN";

export interface UseAuthInterface {
  authUser: AuthUser | null;
  authUserState: AuthUserState;
  signIn: (username: string, password: string) => Promise<void>;
  signUp: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  completeNewPasswordChallenge: (newPass: string) => Promise<void>;
  forgotPasswordCode: (email: string) => Promise<void>;
  forgotPasswordSubmit: (
    username: string,
    code: string,
    newPass: string,
  ) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
}

export default function useAuth(): UseAuthInterface {
  const { authUser, authUserState, setAuthUser }: AuthContext =
    useContext(AuthContextObj);

  async function signIn(username: string, password: string): Promise<void> {
    const nextAuthUser = await Auth.signIn(username, password);
    setAuthUser(nextAuthUser);
  }

  async function signUp(username: string, password: string): Promise<void> {
    const signUpParams = { username, password };

    const signUpResult = await Auth.signUp(signUpParams);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAuthUser(signUpResult.user as any as AuthUser);
  }

  async function signOut(): Promise<void> {
    await Auth.signOut();
    setAuthUser(null);
  }

  async function completeNewPasswordChallenge(newPass: string): Promise<void> {
    if (authUser === null) {
      throw new Error("User is not signed in");
    }

    await Auth.completeNewPassword(authUser, newPass);
    // On success, sign out.
    await signOut();
  }

  async function forgotPasswordCode(email: string): Promise<void> {
    await Auth.forgotPassword(email);
  }

  async function forgotPasswordSubmit(
    username: string,
    code: string,
    newPass: string,
  ): Promise<void> {
    await Auth.forgotPasswordSubmit(username, code, newPass);
  }

  async function changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<void> {
    await Auth.changePassword(authUser, oldPassword, newPassword);
    await signOut();
  }

  const interf: UseAuthInterface = {
    authUser,
    authUserState,
    signIn,
    signUp,
    signOut,
    completeNewPasswordChallenge,
    forgotPasswordCode,
    forgotPasswordSubmit,
    changePassword,
  };

  return interf;
}
