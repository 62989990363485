import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { AuthUser, AuthUserState } from "./withAuthN";
import useAuth, { UseAuthInterface } from "../hooks/useAuth";
import { PageUrl } from "../constants";

export type AuthCondition = (user: AuthUser | null) => boolean;

export default function withAuthZ<Props>(
  AuthedComponent: React.FunctionComponent<Props>,
  authCondition: AuthCondition,
): React.FunctionComponent<Props> {
  return function WithAuthZ(props: Props): null | React.ReactElement {
    const { authUser, authUserState }: UseAuthInterface = useAuth();
    const history = useHistory();
    const location = useLocation();

    const isAuthed = authCondition(authUser);
    if (!isAuthed) {
      if (
        authUserState !== AuthUserState.Updating &&
        location.pathname !== PageUrl.SignIn
      ) {
        history.push(PageUrl.SignIn);
      }

      return null;
    }

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return <AuthedComponent {...(props as any)} />;
  };
}
