import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { PageUrl, Color } from "../../constants";
import { AuthUser } from "../../hocs/withAuthN";
import withAuthZ from "../../hocs/withAuthZ";
import "./Navbar.scss";
import NavbarUser from "../NavbarUser";

function authCondition(authUser: AuthUser | null): boolean {
  return authUser !== null;
}

export interface NavbarProps {
  handleTabSelect: (
    event: React.ChangeEvent<Record<string, unknown>>,
    nextTab: string,
  ) => void;
  location: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
function Navbar(props: NavbarProps): React.ReactElement {
  const { handleTabSelect, location } = props;

  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img
          src={`${process.env.PUBLIC_URL}/UVX-01.svg`}
          className="navbar-logo-img"
          alt="uvx logo"
        />
      </div>
      <div className="navbar-tabs">
        <Tabs
          className="navbar-tabs"
          onChange={handleTabSelect}
          TabIndicatorProps={{
            style: { backgroundColor: Color.Teal, height: "5px" },
          }}
          value={location.pathname}
        >
          <Tab
            className="navbar-tabs"
            label="DASHBOARD"
            value={PageUrl.Dashboard}
          />
          <Tab
            className="navbar-tabs"
            label="REPORTS"
            value={PageUrl.Reports}
          />
        </Tabs>
      </div>
      <NavbarUser />
    </div>
  );
}

export default withAuthZ(Navbar, authCondition);
