import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import useAuth, { UseAuthInterface } from "../../hooks/useAuth";
import Button from "../Button";
import { PageUrl } from "../../constants";
import "./NavbarUser.scss";

export default function NavbarUser(): React.ReactElement | null {
  const { authUser, signOut }: UseAuthInterface = useAuth();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  if (authUser === null || !authUser.attributes) {
    return null;
  }
  const { attributes } = authUser;

  async function handleSignout() {
    try {
      await signOut();
      enqueueSnackbar("Successfully signed out");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar("Error signing out, please try again later", {
        variant: "error",
      });
    }
  }

  return (
    <>
      <div className="navbar-user">
        <a
          href="#"
          className="navbar-dropdown-link"
          onClick={() => setOpen(!open)}
        >
          <span className="navbar-user-email-field">
            &nbsp;HELLO,&nbsp;{attributes.email}
          </span>
          <img
            src="/icons/dropdown.svg"
            alt="User Drop"
            className="nav-bar-dropdown-icon"
            width="10x"
            height="7.44px"
            style={{
              transform: open ? "rotate(180deg)" : "",
            }}
          />
        </a>
        {open && (
          <>
            <div
              className="navbar-dropdown-offclick-overlay"
              aria-hidden="true"
              role="button"
              onClick={() => setOpen(false)}
            />
            <div className="navbar-dropdown">
              <Link
                to={PageUrl.Account}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <Button
                  className="navbar-button"
                  fullWidth
                  color="primary"
                  onClick={() => setOpen(!open)}
                >
                  ACCOUNT SETTINGS
                </Button>
              </Link>
              <hr className="navbar-dropdown-break" />
              <Button
                className="navbar-button"
                fullWidth
                color="primary"
                onClick={handleSignout}
              >
                LOGOUT
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
