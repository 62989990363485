import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./App.scss";

import useDocTitle from "../../hooks/useDocTitle";
import { DeviceNetUtils } from "../../networking";
import Device from "../../lib/Device";
import { PageUrl } from "../../constants";
import withAuthN from "../../hocs/withAuthN";
import useAuth from "../../hooks/useAuth";
import Navbar from "../../components/Navbar";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import SignInPage from "../SignInPage";
import Reports from "../Reports";
import Dashboard from "../DashboardPage";
import Account from "../AccountPage";

function App(): React.ReactElement {
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { authUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  useDocTitle();

  async function fetchDevices() {
    try {
      setLoading(true);

      const nextDevices = await DeviceNetUtils.getDevicesAll();
      // No devices.
      if (nextDevices === null) {
        return;
      }

      nextDevices.sort((a: Device, b: Device) => {
        const av = a.metadata.createdAt;
        const bv = b.metadata.createdAt;

        if (av < bv) {
          return 1;
        }
        if (av > bv) {
          return -1;
        }
        return 0;
      });

      setDevices(nextDevices);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar("Error loading devices", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (authUser === null) {
      return;
    }
    fetchDevices();
  }, [authUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const history = useHistory();

  function handleTabSelect(
    event: React.ChangeEvent<Record<string, unknown>>,
    nextTab: string,
  ) {
    history.push(nextTab);
  }

  return (
    <div className="app">
      <div className="content-wrapper">
        <div className="content-wrapper-inner">
          <Navbar handleTabSelect={handleTabSelect} location={location} />
          <Switch>
            <Route exact path={PageUrl.Dashboard}>
              <Dashboard devices={devices} reload={fetchDevices} />
            </Route>
            <Route exact path={PageUrl.Account}>
              <Account />
            </Route>
            <Route exact path={PageUrl.SignIn}>
              <SignInPage />
            </Route>
            <Route exact path={PageUrl.Reports}>
              {devices.length === 0 ? (
                <div className="no-devices">No Zeners found.</div>
              ) : (
                <Reports devices={devices} />
              )}
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
      {loading && <Loading message="Loading devices..." />}
    </div>
  );
}

export default withAuthN(App);
