import React from "react";
import { Portal } from "react-portal";
import classnames from "classnames";
import loadingIcon from "../../icons/status.png";
import "./Loading.scss";

interface LoadingProps {
  message: string;
  overlay?: boolean;
}

export default function Loading(props: LoadingProps): React.ReactElement {
  const { message, overlay } = props;

  return (
    <Portal>
      <div className={classnames("loading-overlay", overlay && "translucent")}>
        <div className="loading-overlay-container">
          <img className="loading-image" src={loadingIcon} alt="Loading" />
          <span className="loading-message">{message}</span>
        </div>
      </div>
    </Portal>
  );
}
