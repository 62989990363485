import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import classnames from "classnames";

import Device from "../../lib/Device";
import Button from "../Button";
import { UvLampOpMode } from "../../constants";
import {
  opModeToTitle,
  opModeToIcon,
  opModeToColor,
} from "../../utils/stringifiers";

import "./ZenerDetailsMutable.scss";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ContIcon = opModeToIcon(UvLampOpMode.Continuous)!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const HuNotDetIcon = opModeToIcon(UvLampOpMode.HumanNotDetected)!;

export interface ZenerDetailsMutableProps {
  zener: Device;
  onSave: (saved: Device) => void;
  onCancel: () => void;
}
export default function ZenerDetailsMutable(
  props: ZenerDetailsMutableProps,
): React.ReactElement {
  const { zener, onSave, onCancel } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [heightStr, setHeightStr] = useState("");
  const [height, setHeight] = useState(0);
  const [heightError, setHeightError] = useState<null | Error>(null);
  const [opMode, setOpMode] = useState(UvLampOpMode.Continuous);

  useEffect(() => {
    setName(zener.metadata.name || "");
    setDescription(zener.metadata.description || "");
    setHeightStr(String(zener.metadata.height) || "");
    setHeight(zener.metadata.height);
    setOpMode(zener.control.uvLampOpMode);
  }, []);

  function handleHeightChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const str = evt.target.value;
    setHeightStr(str);

    if (str === "") {
      setHeightError(new Error("Must be a valid number"));
      return;
    }

    const num = Number(str);
    if (num === undefined || num === null || Number.isNaN(num)) {
      setHeightError(new Error("Must be a valid number"));
      return;
    }

    setHeightError(null);
    setHeight(num);
  }

  function handleSave() {
    const next = zener.clone();
    next.metadata.name = name;
    next.metadata.description = description;
    next.metadata.height = height;
    next.control.uvLampOpMode = opMode;

    onSave(next);
  }

  return (
    <div className="zener-details-mutable">
      <TextField
        fullWidth
        label="Name"
        value={name}
        onChange={(evt) => setName(evt.target.value)}
      />
      <TextField
        fullWidth
        multiline
        label="Description"
        value={description}
        onChange={(evt) => setDescription(evt.target.value)}
      />
      <TextField
        error={heightError !== null}
        helperText={heightError !== null && "Must be a valid number"}
        InputProps={{
          endAdornment: <InputAdornment position="end">meters</InputAdornment>,
        }}
        type="number"
        onChange={handleHeightChange}
        fullWidth
        label="Installation Height"
        value={heightStr}
      />
      <TextField
        className={classnames("zener-details-mutable-op-mode-option", opMode)}
        fullWidth
        multiline
        select
        label="Operating Mode"
        value={opMode}
        onChange={(evt) => setOpMode(evt.target.value as UvLampOpMode)}
      >
        <MenuItem
          className="zener-details-mutable-op-mode-option"
          value={UvLampOpMode.Continuous}
          style={{ color: opModeToColor(UvLampOpMode.Continuous) }}
        >
          <ContIcon />
          {opModeToTitle(UvLampOpMode.Continuous)}
        </MenuItem>
        <MenuItem
          className="zener-details-mutable-op-mode-option"
          value={UvLampOpMode.HumanNotDetected}
          style={{ color: opModeToColor(UvLampOpMode.HumanNotDetected) }}
        >
          <HuNotDetIcon />
          {opModeToTitle(UvLampOpMode.HumanNotDetected)}
        </MenuItem>
      </TextField>
      <div className="action-row">
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}
