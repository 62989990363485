import React from "react";
import classnames from "classnames";

import "./SearchBar.scss";

export interface SearchBarProps {
  label?: string;
  value?: string | null;
  onChange?: (text: string) => void;
  className?: string;
}

export default function SearchBar(props: SearchBarProps): React.ReactElement {
  const { label, onChange, value, className } = props;

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    if (onChange === undefined) {
      return;
    }
    const text = evt.currentTarget.value;
    onChange(text);
  }

  return (
    <div className={classnames("searchbar", className)}>
      {label !== undefined && <span className="searchbar-label">{label}</span>}
      <br />
      <div className="searchbar-input-container">
        <input
          className="searchbar-input"
          placeholder="Search"
          onChange={handleChange}
          value={value !== null ? `${value}` : ""}
        />
        <img src="/icons/searchbar.svg" className="searchbar-icon" alt="" />
      </div>
    </div>
  );
}
