import React, { useState } from "react";
import { Portal } from "react-portal";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import "./ForgotPasswordModal.scss";

interface ForgotPasswordModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ResetPasswordWithCodeProps {
  handleSubmit: () => void;
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  cPassword: string;
  setCPassword: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPass: React.Dispatch<React.SetStateAction<boolean>>;
}

interface EnterEmailForCodeProps {
  handleEmailCode: () => void;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setResetPass: React.Dispatch<React.SetStateAction<boolean>>;
}

function EnterEmailForCode(props: EnterEmailForCodeProps): React.ReactElement {
  const { handleEmailCode, setEmail, setOpen, setResetPass } = props;
  return (
    <div className="forgot-password-email">
      <span className="forgot-password-heading">Enter your email:</span>
      <input
        className="email-input"
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
      />
      <span className="code-reset-info">
        Your email will receive a code to reset your password
      </span>
      <div className="forgot-password-email-action">
        <button
          className="action-button cancel-email"
          type="button"
          onClick={() => {
            setOpen(false);
            setResetPass(false);
          }}
        >
          CANCEL
        </button>
        <button
          className="action-button submit-email"
          type="button"
          onClick={handleEmailCode}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

function ResetPasswordWithCode(
  props: ResetPasswordWithCodeProps,
): React.ReactElement {
  const {
    handleSubmit,
    code,
    setCode,
    password,
    setPassword,
    cPassword,
    setCPassword,
    setOpen,
    setResetPass,
  } = props;
  return (
    <div className="reset-with-code">
      <div className="input-container">
        <span className="forgot-password-heading">Enter your code: </span>
        <input
          className="input-field"
          value={code}
          onChange={(evt) => setCode(evt.target.value)}
        />
      </div>
      <div className="input-container">
        <span className="forgot-password-heading">New Password: </span>
        <input
          className="input-field"
          type="password"
          value={password}
          onChange={(evt) => setPassword(evt.target.value)}
        />
      </div>
      <div className="input-container">
        <span className="forgot-password-heading">Confirm Password: </span>
        <input
          className="input-field"
          type="password"
          value={cPassword}
          onChange={(evt) => setCPassword(evt.target.value)}
        />
      </div>
      <div className="forgot-password-email-action">
        <button
          className="cancel-email"
          type="button"
          onClick={() => {
            setOpen(false);
            setResetPass(false);
          }}
        >
          CANCEL
        </button>
        <button
          className="submit-email"
          type="button"
          onClick={() => handleSubmit()}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
}

export default function ForgotPasswordModal(
  props: ForgotPasswordModalProps,
): React.ReactElement {
  const { open, setOpen } = props;
  const [code, setCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [cPassword, setCPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [resetPass, setResetPass] = useState<boolean>(false);

  const { forgotPasswordCode, forgotPasswordSubmit } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  async function handleEmailCode() {
    if (email === "") {
      enqueueSnackbar("Email cannot be empty", { variant: "error" });
      return;
    }

    try {
      await forgotPasswordCode(email);
      setResetPass(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  async function handleSubmit(evt?: React.FormEvent<HTMLFormElement>) {
    if (evt !== undefined) {
      evt.preventDefault();
    }

    if (code === "") {
      enqueueSnackbar("Code cannot be empty", { variant: "error" });
      return;
    }

    if (password === "") {
      enqueueSnackbar("New password cannot be empty", { variant: "error" });
      return;
    }

    if (password !== cPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      return;
    }

    try {
      await forgotPasswordSubmit(email, code, password);
      enqueueSnackbar("Successfully changed password", { variant: "success" });
      setOpen(false);
      setResetPass(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  return (
    <Portal>
      {open && (
        <div className="forgot-password-modal-container">
          <div
            className="forgot-password-overlay"
            aria-hidden="true"
            role="button"
            onClick={() => {
              setOpen(false);
              setResetPass(false);
            }}
          />

          <div className="forgot-password-modal">
            <div className="forgot-password-titlespace">
              <div className="titlespace-span-container">
                <span className="titlespace-span">FORGOT PASSWORD</span>
              </div>
              <button
                className="close-modal"
                type="button"
                onClick={() => {
                  setOpen(false);
                  setResetPass(false);
                }}
              >
                X
              </button>
            </div>
            {resetPass ? (
              <ResetPasswordWithCode
                handleSubmit={handleSubmit}
                code={code}
                setCode={setCode}
                cPassword={cPassword}
                setCPassword={setCPassword}
                password={password}
                setPassword={setPassword}
                setOpen={setOpen}
                setResetPass={setResetPass}
              />
            ) : (
              <EnterEmailForCode
                handleEmailCode={handleEmailCode}
                setEmail={setEmail}
                setOpen={setOpen}
                setResetPass={setResetPass}
              />
            )}
          </div>
        </div>
      )}
    </Portal>
  );
}
