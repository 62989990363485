import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";

import useAuth from "../../hooks/useAuth";

import "./ResetPasswordModal.css";

interface ResetPasswordModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ResetPasswordModal(
  props: ResetPasswordModalProps,
): React.ReactElement {
  const { open, setOpen } = props;
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { completeNewPasswordChallenge } = useAuth();

  async function handleSubmit(evt?: React.FormEvent<HTMLFormElement>) {
    if (evt !== undefined) {
      evt.preventDefault();
    }

    if (password === "") {
      enqueueSnackbar("New password cannot be empty", { variant: "error" });
      return;
    }

    if (password !== cPassword) {
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      return;
    }

    try {
      await completeNewPasswordChallenge(password);
      enqueueSnackbar("Successfully changed password", { variant: "success" });
      setOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            fullWidth
            label="New Password"
            type="password"
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
          />
          <TextField
            autoFocus
            fullWidth
            label="Confirm New Password"
            type="password"
            value={cPassword}
            onChange={(evt) => setCPassword(evt.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
