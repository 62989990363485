import React, { useState } from "react";
import { Portal } from "react-portal";
import { useSnackbar } from "notistack";

import Device from "../../lib/Device";
import ZenerDetailsImmutable from "./ZenerDetailsImmutable";
import ZenerDetailsMutable from "./ZenerDetailsMutable";
import Loading from "../Loading";
import { ZenerNetUtils, DeviceNetUtils } from "../../networking";

import "./ZenerDetailsModal.scss";

export interface ZenerDetailsModalProps {
  zener: Device;
  onClose: () => void;
  onReloadDevices: () => void;
}
export default function ZenerDetailsModal(
  props: ZenerDetailsModalProps,
): React.ReactElement {
  const { zener, onClose, onReloadDevices } = props;
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleSave(nextDevice: Device) {
    try {
      setLoading(true);
      const zProm = ZenerNetUtils.postControl(
        nextDevice.deviceId(),
        undefined,
        nextDevice.control.uvLampOpMode,
      );
      const dProm = DeviceNetUtils.postDevice(
        nextDevice.deviceId(),
        nextDevice.metadata.name || undefined,
        nextDevice.metadata.description,
        nextDevice.metadata.height,
      );

      await Promise.all([zProm, dProm]);
      enqueueSnackbar("Successfully updated device", { variant: "success" });
      onReloadDevices();
      onClose();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar("Unable to update device. Please try again later.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Portal>
      <div className="zener-details-modal" onClick={onClose}>
        <div
          className="content-container"
          onClick={(evt) => evt.stopPropagation()}
        >
          {loading ? (
            <Loading message="Updating device..." />
          ) : (
            <>
              <div className="table-container">
                {editing ? (
                  <ZenerDetailsMutable
                    zener={zener}
                    onCancel={onClose}
                    onSave={handleSave}
                  />
                ) : (
                  <ZenerDetailsImmutable
                    onClose={onClose}
                    zener={zener}
                    onStartEditing={() => setEditing(true)}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Portal>
  );
}
